import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CardImage from '../CardImage';
import { useAppDispatch, useAppSelector } from '../../../../stores/store';
import { fetchMonitorDetail, monitorDetailSelector } from '../../../../stores/slices/MonitorDetailSlice';
import { MonitorDetailRequest } from '../../../../models/monitor/MonitorDetailRequest.model';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import { io } from 'socket.io-client';
import config from '../../../../config';
import { eUserRole } from '../../../../services/Constants/enum/enum.model';
import { userRolesSelector } from '../../../../stores/slices/UserRolesSlice';

const TransparentAccordion = styled(Accordion)(({ theme }) => ({
    backgroundColor: '#EFEFEF',
    borderRadius: '8px',
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
}));

const MonitorDetail: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { data: dataMonitorDetail, isLoading, isError, errorMessage } = useAppSelector(monitorDetailSelector);
    const [socketData, setSocketData] = useState<{ [key: string]: { [key: string]: string } }>({});

    const userRole = useAppSelector(userRolesSelector);
    const hasRole = (roles: eUserRole[]): boolean => {
        if (!userRole.data) return false;
        if (Array.isArray(userRole.data)) {
            return userRole.data.some((role) => roles.includes(role.role_account_id as eUserRole));
        }
        return roles.includes(userRole.data.role_account_id as eUserRole);
    };
    useEffect(() => {
        const requestData: MonitorDetailRequest = {
            floodgate_id: id || '',  // ตรวจสอบว่ามี id หรือไม่
        };
        dispatch(fetchMonitorDetail(requestData));

        const socket = io(config.ws.ws_floodgate_guest_url);

        socket.on('data', (data) => {
            const newData = data.reduce((acc: any, item: any) => {
                acc[item.floodgate_id] = item.floodgate_transaction_details;
                acc[item.floodgate_id].floodgate_transaction_createdate = item.floodgate_transaction_createdate;
                return acc;
            }, {});

            setSocketData(newData);
        });

        return () => {
            socket.disconnect();
        };
    }, [dispatch, id]);

    if (isLoading) {
        return <CircularProgress />;
    }

    if (isError) {
        return <div>Error: {errorMessage}</div>;
    }
    const filteredSettingNodes = dataMonitorDetail?.response.setting_nodes.filter(node =>
        hasRole([eUserRole.Operator])
            ? !node.nodetype_input_and_display.some(display => display.is_control) // กรอง setting_node ที่ไม่มี is_control สำหรับ Operator
            : true // ถ้าไม่ใช่ Operator ให้แสดงทั้งหมด
    );
    return (
        <Box sx={{ padding: 2 }}>
            <TransparentAccordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                    <Typography variant="h6">{dataMonitorDetail?.response.floodgate_name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {/* ตรวจสอบว่ามีข้อมูลก่อนการ map */}
                        {filteredSettingNodes && filteredSettingNodes.map((node, index) => (
                            !node?.setting_node_type_communication
                            && node.nodetype_input_and_display.length > 0 && (
                                <Grid item xs={12} sm={6} md={6} lg={6} key={node.setting_node_id}>
                                    <CardImage path='FlapGateWeirCurved1' imagename='0' settingNodes={node} socketData={socketData} />
                                </Grid>
                            )
                        ))}
                    </Grid>
                </AccordionDetails>
            </TransparentAccordion>
        </Box>
    );
}

export default MonitorDetail;
