import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal, Fade, Backdrop, Grid, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import { useAppDispatch } from '../../../stores/store';
import { manageFloodGateNodeDisplay } from '../../../stores/slices/ManageFloodgateNodeTypeDisplaySlice';
import { floodgateImage, FloodgateImageDisplayNames, floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { ManageFloodgateNodeTypeDisplayRequest } from '../../../models/manageFloodgateNodeTypeDisplay/ManageFloodgateNodeTypeDisplayRequest.model';
import { toast } from 'react-toastify'; // Import react-toastify
import 'react-toastify/dist/ReactToastify.css';

interface AddModalProps {
    open: boolean;
    onClose: () => void;
    settingNodeId: string;
}

const ManageProgramTemplateDisplayAddModal: React.FC<AddModalProps> = ({ open, onClose, settingNodeId = "" }) => {
    const dispatch = useAppDispatch();

    // Form data state
    const [formData, setFormData] = useState<ManageFloodgateNodeTypeDisplayRequest>({
        setting_node_id: settingNodeId,
        is_control: false,
        is_btn: false,
        btn_value: '',
        btn_name: '',
        btn_color: '#ffffff',
        is_text_input: false,
        text_input_name: '',
        is_monitor: false,
        is_status_light: false,
        status_light_value: '',
        status_light_name: '',
        status_light_color: '#ffffff',
        is_graph: false,
        graph_color: '#ffffff',
        is_img: false,
        img_group_name: ''
    });

    // Validation errors state
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    // Color picker open state
    interface ColorPickerState {
        btn_color: boolean;
        status_light_color: boolean;
        graph_color: boolean;
    }

    const [colorPickerOpen, setColorPickerOpen] = useState<ColorPickerState>({
        btn_color: false,
        status_light_color: false,
        graph_color: false,
    });

    // Handle color picker toggle
    const toggleColorPicker = (field: keyof ColorPickerState) => {
        setColorPickerOpen((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    // Handle color change
    const handleColorChange = (field: keyof ColorPickerState, color: string) => {
        setFormData((prev) => ({ ...prev, [field]: color }));
    };

    // Validation function
    const validate = () => {
        let tempErrors: { [key: string]: string } = {};

        // ตรวจสอบกรณีที่เลือก "ควบคุมระบบ"
        if (formData.is_control) {
            // กรณีเลือกปุ่มควบคุม (is_btn)
            if (formData.is_btn) {
                if (!formData.btn_value) tempErrors.btn_value = 'กรุณากรอกค่าที่จะส่งไปเมื่อกดปุ่ม';
                if (!formData.btn_name) tempErrors.btn_name = 'กรุณากรอกชื่อปุ่ม';
            }

            // กรณีเลือกการป้อนข้อมูล (is_text_input)
            if (formData.is_text_input) {
                if (!formData.text_input_name) tempErrors.text_input_name = 'กรุณากรอกชื่อ Text Input';
            }
        }

        // ตรวจสอบกรณีที่เลือก "มอนิเตอร์ข้อมูล"
        if (formData.is_monitor) {
            // กรณีเลือกไฟสถานะ (is_status_light)
            if (formData.is_status_light) {
                if (!formData.status_light_value) tempErrors.status_light_value = 'กรุณากรอกค่าไฟสถานะ';
                if (!formData.status_light_name) tempErrors.status_light_name = 'กรุณากรอกชื่อไฟสถานะ';
            }

            // กรณีเลือกแสดงกราฟ (is_graph)
            if (formData.is_graph) {
                if (!formData.graph_color) tempErrors.graph_color = 'กรุณาเลือกสีกราฟ';
            }
        }

        // กรณีเลือกการแสดงผลรูปภาพ (is_img)
        if (formData.is_img) {
            if (!formData.img_group_name) tempErrors.img_group_name = 'กรุณากรอกชื่อกลุ่มรูปภาพ';
        }

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const canSubmit = () => {
        return (formData.is_control && (formData.is_text_input || formData.is_btn)) ||
            (formData.is_monitor && (formData.is_img || formData.is_graph || formData.is_status_light));
    };


    // Handle form submission
    const handleSubmit = async () => {
        if (validate()) {
            try {

                formData.btn_value = formData.btn_value == '' ? 'NA' : formData.btn_value;
                formData.btn_name = formData.btn_name == '' ? 'NA' : formData.btn_name;
                formData.text_input_name = formData.text_input_name == '' ? 'NA' : formData.text_input_name;
                formData.status_light_value = formData.status_light_value == '' ? 'NA' : formData.status_light_value;
                formData.status_light_name = formData.status_light_name == '' ? 'NA' : formData.status_light_name;
                formData.img_group_name = formData.img_group_name == '' ? 'NA' : formData.img_group_name;

                await dispatch(manageFloodGateNodeDisplay({
                    action: floodgateManageAction.ADD,
                    body: formData
                })).unwrap();
                toast.success('เพิ่มข้อมูลสำเร็จ');
                onClose();
            } catch (error) {
                toast.error('เกิดข้อผิดพลาดในการบันทึกข้อมูล');
            }
        }
    };

    return (
        <Modal open={open} onClose={onClose} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade in={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography variant="h6" gutterBottom>เพิ่มข้อมูลการแสดงผลเซ็นเซอร์และการควบคุม</Typography>
                    <Grid container spacing={3}>
                        {/* Control Checkbox */}
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.is_control}
                                        onChange={(e) => setFormData({ ...formData, is_control: e.target.checked })}
                                        disabled={formData.is_monitor}
                                    />
                                }
                                label="ควบคุมระบบ"
                            />
                        </Grid>
                        {formData.is_control && (
                            <>
                                {/* Text Input */}
                                <Grid item xs={12} sm={2}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.is_text_input}
                                                onChange={(e) => setFormData({ ...formData, is_text_input: e.target.checked })}
                                                disabled={formData.is_btn}
                                            />
                                        }
                                        label="แบบป้อนข้อมูล :"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="ชื่อ Text Input"
                                        value={formData.text_input_name}
                                        onChange={(e) => setFormData({ ...formData, text_input_name: e.target.value })}
                                        error={!!errors.text_input_name}
                                        helperText={errors.text_input_name}
                                        disabled={!formData.is_text_input}
                                    />
                                </Grid>

                                {/* Button Control */}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.is_btn}
                                                onChange={(e) => setFormData({ ...formData, is_btn: e.target.checked })}
                                                disabled={formData.is_text_input}
                                            />
                                        }
                                        label="ปุ่มควบคุม :"
                                    />
                                </Grid>
                                {formData.is_btn && (
                                    <>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="ค่าที่จะส่งไปเมื่อกดปุ่ม"
                                                value={formData.btn_value}
                                                onChange={(e) => setFormData({ ...formData, btn_value: e.target.value })}
                                                error={!!errors.btn_value}
                                                helperText={errors.btn_value}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="ชื่อปุ่ม"
                                                value={formData.btn_name}
                                                onChange={(e) => setFormData({ ...formData, btn_name: e.target.value })}
                                                error={!!errors.btn_name}
                                                helperText={errors.btn_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1.5}>
                                            <Button variant="contained" onClick={() => toggleColorPicker('btn_color')} sx={{ mt: 2 }}>
                                                เลือกสีปุ่ม
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={2.5}>
                                            {colorPickerOpen.btn_color && (
                                                <Box mt={2}>
                                                    <HexColorPicker
                                                        color={formData.btn_color}
                                                        onChange={(color) => handleColorChange('btn_color', color)}
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                        {/* Monitor Checkbox (เพิ่มเข้ามา) */}
                        <Grid item xs={12} sm={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.is_monitor}
                                        onChange={(e) => setFormData({ ...formData, is_monitor: e.target.checked })}
                                        disabled={formData.is_control}
                                    />
                                }
                                label="มอนิเตอร์ข้อมูล"
                            />
                        </Grid>
                        {formData.is_monitor && (
                            <>
                                {/* Status Light */}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.is_status_light}
                                                onChange={(e) => setFormData({ ...formData, is_status_light: e.target.checked })}
                                            />
                                        }
                                        label="ไฟสถานะ"
                                    />
                                </Grid>
                                {formData.is_status_light && (
                                    <>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="ค่าไฟสถานะ"
                                                value={formData.status_light_value}
                                                onChange={(e) => setFormData({ ...formData, status_light_value: e.target.value })}
                                                error={!!errors.status_light_value}
                                                helperText={errors.status_light_value}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <TextField
                                                fullWidth
                                                label="ชื่อไฟสถานะ"
                                                value={formData.status_light_name}
                                                onChange={(e) => setFormData({ ...formData, status_light_name: e.target.value })}
                                                error={!!errors.status_light_name}
                                                helperText={errors.status_light_name}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1.5}>
                                            <Button variant="contained" onClick={() => toggleColorPicker('status_light_color')} sx={{ mt: 2 }}>
                                                เลือกสีไฟสถานะ
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={2.5}>
                                            {colorPickerOpen.status_light_color && (
                                                <Box mt={2}>
                                                    <HexColorPicker
                                                        color={formData.status_light_color}
                                                        onChange={(color) => handleColorChange('status_light_color', color)}
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                    </>
                                )}

                                {/* Graph */}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.is_graph}
                                                onChange={(e) => setFormData({ ...formData, is_graph: e.target.checked })}
                                            />
                                        }
                                        label="แสดงกราฟ :"
                                    />
                                </Grid>
                                {formData.is_graph && (
                                    <>
                                        <Grid item xs={12} sm={1.5}>
                                            <Button variant="contained" onClick={() => toggleColorPicker('graph_color')} sx={{ mt: 2 }}>
                                                เลือกสีกราฟ
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            {colorPickerOpen.graph_color && (
                                                <Box mt={2}>
                                                    <HexColorPicker
                                                        color={formData.graph_color}
                                                        onChange={(color) => handleColorChange('graph_color', color)}
                                                    />
                                                </Box>
                                            )}
                                        </Grid>
                                    </>
                                )}

                                {/* Image Group */}
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={formData.is_img}
                                                onChange={(e) => setFormData({ ...formData, is_img: e.target.checked })}
                                            />
                                        }
                                        label="รูปภาพ"
                                    />
                                </Grid>
                                {formData.is_img && (
                                    <>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel>ชื่อกลุ่มรูปภาพ</InputLabel>
                                                <Select
                                                    value={formData.img_group_name}
                                                    onChange={(e) => setFormData({ ...formData, img_group_name: e.target.value })}
                                                    label="ชื่อกลุ่มรูปภาพ"
                                                >
                                                    {Object.values(floodgateImage).map((key) => (
                                                        <MenuItem key={key} value={key}>
                                                            {FloodgateImageDisplayNames[key as floodgateImage]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}
                    </Grid>
                    <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 3 }} disabled={!canSubmit()}>
                        บันทึกข้อมูล
                    </Button>
                </Box >
            </Fade >
        </Modal >
    );
};

export default ManageProgramTemplateDisplayAddModal;