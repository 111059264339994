import './lib/css/index.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";

import App from './App';
import reportWebVitals from './reportWebVitals';

import store from "./stores/store";

if (process.env.NODE_ENV === 'development') {
  console.log("Running in development mode");
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const stores = store;
export const history = createBrowserHistory();

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
