const config = {
    user_info: {
        user_url: process.env.REACT_APP_USER_URL || 'http://104.43.92.67:3000',
        user_authenticatin: process.env.REACT_APP_USER_AUTHEN || '/user/authentication',
        user_authenticatin_check: process.env.REACT_APP_USER_AUTHEN_CHECK || '/user/authentication/check',
        user_roles: process.env.REACT_APP_USER_ROLES || '/user/roles',
        user_userInfo: process.env.REACT_APP_USER_INFO || '/user/UserInfo',
        user_register: process.env.REACT_APP_USER_REGISTER || '/user/manageusers',
    },
    floodgate: {
        floodgate_url: process.env.REACT_APP_FLOODGATE_URL || 'http://104.43.92.67:3001',
        floodgate_info: process.env.REACT_APP_FLOODGATE_INFO || '/floodgate/FloodgateInfo',
        floodgate_detail: process.env.REACT_APP_FLOODGATE_DETAIL || '/floodgate/FloodgateInfo/Detail',
        floodgate_manage: process.env.REACT_APP_FLOODGATE_MANAGE || '/floodgates/manage/info',
        floodgate_manage_schema: process.env.REACT_APP_FLOODGATE_MANAGE_SCHEMA || '/floodgates/manage/schema',
        floodgate_manage_node_type: process.env.REACT_APP_FLOODGATE_MANAGE_NODE_TYPE || '/floodgates/manage/type',
        floodgate_manage_node: process.env.REACT_APP_FLOODGATE_MANAGE_NODE || '/floodgates/manage/node',
        floodgate_manage_node_type_display: process.env.REACT_APP_FLOODGATE_MANAGE_NODE_TYPE_DISPLAY || '/floodgates/manage/nodetypedisplay',
        floodgate_manage_relation: process.env.REACT_APP_FLOODGATE_MANAGE_RELATION || '/floodgates/manage/relation',
        floodgate_manage_info_image: process.env.REACT_APP_FLOODGATE_MANAGE_INFO_IMAGE || '/floodgates/manage/info/image',
    },
    control: {
        floodgate_control_url: process.env.REACT_APP_FLOODGATE_SYS_URL || 'http://104.43.92.67:3002',
        floodgate_control: process.env.REACT_APP_FLOODGATE_CONTROL_URL || '/floodgate/Control',
    },
    ws: {
        ws_floodgate_guest_url: process.env.REACT_WS_FLOODGATE_GUEST_URL || 'http://104.43.92.67:3004'
    }
};

export default config;