import React, { useEffect, useState } from 'react';
import { Modal, Box, Button, Checkbox, FormControlLabel, Typography, CircularProgress } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { fetchFloodGateInfo, floodGateInfoSelector } from '../../../stores/slices/FloodGateInfoSlice';
import { floodgateAction, floodgateManageAction } from '../../../services/Constants/enum/enum.model';
import { floodgateRelationSelector, manageFloodgateRelation } from '../../../stores/slices/FloodgateRelationSlice';
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify';

interface Floodgate {
    floodgate_id: string;
    floodgate_name: string;
}

interface MenuItem {
    name: string;
    value: string;
}

interface PermissionModalProps {
    open: boolean;
    handleClose: () => void;
    account_id: string; 
}

const PermissionModal = ({ open, handleClose, account_id }: PermissionModalProps) => {
    const dispatch = useAppDispatch();
    const { 
        dataByUser, dataByAdmin, 
        isLoadingByUser, isLoadingByAdmin, 
        isErrorByUser, isErrorByAdmin, 
        errorMessageByUser, errorMessageByAdmin 
    } = useAppSelector(floodGateInfoSelector);

    const { dataForAdd:relationData, dataForFetch ,isError }= useAppSelector(floodgateRelationSelector);
    
     // ใช้ useEffect เพื่อเรียก dispatch เมื่อ modal เปิด
     useEffect(() => {
        if (open) {
            const getData = async () => {
                try {
                    await Promise.all([
                        dispatch(fetchFloodGateInfo(floodgateAction.getAllByAdmin)),
                        dispatch(manageFloodgateRelation({
                            action: floodgateManageAction.GET
                        }))
                    ]);
                }catch(e){
                    console.log(e);
                }
            }
            getData()
        }
    }, [dispatch, open]);

    const dataGuestItems: MenuItem[] = dataByAdmin
    ? dataByAdmin.map((item: Floodgate) => ({
        name: item.floodgate_name,
        value: item.floodgate_id,
    }))
    : [];
    
    // กำหนดค่าเริ่มต้นของสิทธิ์ที่เลือกไว้
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
    useEffect(() => {
        if (dataForFetch) {
            const floodgateIds = dataForFetch.filter(f=> f.account_id == account_id).map(item => item.floodgate_id);
            setSelectedPermissions(floodgateIds);
        }
    }, [dataForFetch]);

    // ฟังก์ชันเมื่อเลือก/ยกเลิกการเลือกสิทธิ์
    const handlePermissionChange = async (menuValue: string) => {
        if (selectedPermissions.includes(menuValue)) {
            // ถ้าติ๊กออก ให้ส่งคำสั่งไปลบสิทธิ์
            try {
                await dispatch(
                    manageFloodgateRelation({
                        action: floodgateManageAction.DELETE,
                        floodgate_relation_id: dataForFetch?.filter(f=> f.account_id == account_id && f.floodgate_id == menuValue)
                        .map(item => item.floodgate_relation_id).toString(),
                        account_id: account_id, 
                    })
                );
                if(isError){
                    toast.error('บันทึกข้อมูลไม่สำเร็จ');
                }else{
                    toast.success('บันทึกข้อมูลสำเร็จ');
                }
            } catch (error) {
                toast.error('บันทึกข้อมูลไม่สำเร็จ');
            }
        } else {
            // ถ้าติ๊กถูก ให้ส่งคำสั่งไปเพิ่มสิทธิ์
            try {
                await dispatch(
                    manageFloodgateRelation({
                        action: floodgateManageAction.ADD,
                        account_id: account_id, 
                        floodgate_id: menuValue,
                    })
                );
                if(isError){
                    toast.error('บันทึกข้อมูลไม่สำเร็จ');
                }else{
                    toast.success('บันทึกข้อมูลสำเร็จ');
                }
            } catch (error) {
                toast.error('บันทึกข้อมูลไม่สำเร็จ');
            }
        }

        // อัปเดต selectedPermissions
        setSelectedPermissions((prev) =>
            prev.includes(menuValue)
                ? prev.filter((value) => value !== menuValue)
                : [...prev, menuValue]
        );
    };

    if (isLoadingByUser || isLoadingByAdmin) {
        return (
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <CircularProgress />
                </Box>
            </Modal>
        );
    }

    if (isErrorByUser || isErrorByAdmin) {
        return (
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        borderRadius: 2,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography color="error">Error: {errorMessageByUser || errorMessageByAdmin}</Typography>
                </Box>
            </Modal>
        );
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography variant="h6" component="h2" sx={{paddingBottom:"10px"}}>
                    กำหนดสิทธิ์การเข้าถึงโครงการ
                </Typography>
                <hr/>
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 1, 
                    maxHeight: '400px', // กำหนดความสูงสูงสุด
                    overflowY: 'auto',  // ให้สามารถเลื่อนดูข้อมูลได้ถ้าสูงเกิน
                }}>
                    {dataGuestItems.map((item) => (
                        <FormControlLabel
                            key={item.value}
                            control={
                                <Switch
                                    checked={selectedPermissions.includes(item.value)}
                                    onChange={() => handlePermissionChange(item.value)}
                                />
                            }
                            label={item.name}
                        />
                    ))}

                </Box>
            </Box>
        </Modal>
    );
};

export default PermissionModal;