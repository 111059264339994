import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import config from "../../config";

// Interface สำหรับ State
interface FloodgateTransationMonitorState {
    data: Array<{ [key: string]: { WaterLevel: string; floodgate_transaction_createdate: string } }> | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: FloodgateTransationMonitorState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการดึงข้อมูล transation monitor
export const fetchTransationMonitor = createAsyncThunk<
    Array<{ [key: string]: { WaterLevel: string; floodgate_transaction_createdate: string } }>, // Response type
    { floodgate_id: string }, // Request payload type
    { rejectValue: string }
>(
    "transationMonitor/fetchTransationMonitor",
    async ({ floodgate_id }, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }
            const response = await httpClient.post(
                `${config.floodgate.floodgate_url}/floodgate/transation/monitor`,
                { floodgateId: floodgate_id },
                {
                    headers: {
                        'authorization': token, // ใส่ token ใน header
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data; // ส่งข้อมูลที่เป็น array กลับไป
            } else {
                return rejectWithValue(response.data.message || "ไม่สามารถดึงข้อมูลได้");
            }

        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด");
        }
    }
);

// สร้าง Slice
const transationMonitorSlice = createSlice({
    name: "transationMonitor",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransationMonitor.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                fetchTransationMonitor.fulfilled,
                (state, action: PayloadAction<Array<{ [key: string]: { WaterLevel: string; floodgate_transaction_createdate: string } }>>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                fetchTransationMonitor.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด";
                }
            );
    },
});

// Selector สำหรับดึงข้อมูล transation monitor จาก store
export const transationMonitorSelector = (store: RootState) => store.transationMonitor;

// Export reducer
export default transationMonitorSlice.reducer;
