import * as React from 'react';
import { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Paper, Box, Typography, Button, Grid, Modal, Backdrop, Fade, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { floodGateInfoSelector, fetchFloodGateInfo } from '../../../stores/slices/FloodGateInfoSlice';
import { SettingNode } from '../../../models/floodgateInfo/FloodGateInfoGuestRes.model';
import { floodgateAction } from '../../../services/Constants/enum/enum.model';
import { io } from 'socket.io-client';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';

interface FloodgateSettingType {
  setting_type_id: string;
  setting_type_group: string;
  setting_type_name: string;
}

interface FloodgateSettingNode {
  setting_node_id: string;
  setting_type_id: string;
  floodgate_id: string;
  setting_node_data_display_main: boolean;
  setting_node_data_key: string;
  setting_node_data_name: string;
  setting_node_data_min: string;
  setting_node_data_max: string;
  setting_node_data_unit: string;
  setting_node_type_communication: boolean;
  setting_node_data_type: string;
  setting_node_sequence: string;
  setting_node_discription: string;
  setting_type: FloodgateSettingType;
}

interface FloodgateInfo {
  floodgate_id: string;
  floodgate_name: string;
  floodgate_attachment_file: string;
  floodgate_ip: string;
  floodgate_port: string;
  floodgate_username: string;
  floodgate_password: string;
  floodgate_ns: string;
  floodgate_s: string;
  floodgate_obj: string;
  floodgate_project: string;
  floodgate_schema_type_id: string;
  setting_nodes: FloodgateSettingNode[];
}

interface FloodgateRelation {
  floodgate_id: string;
  floodgate_relation_id: string;
  account_id: string;
  floodgate_info: FloodgateInfo;
}

const TransparentAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: '#EFEFEF',
  borderRadius: '8px',
  boxShadow: 'none',
  marginBottom: theme.spacing(2),
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderRadius: '12px',
  backgroundColor: 'rgba(255, 255, 255, 1)',
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const CustomModal = styled(Modal)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  width: '40%',
  maxHeight: '70vh',
  overflowY: 'auto',
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  borderRadius: '12px',
  boxShadow: 'none',
}));

const SettingItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}));

interface CustomCardProps {
  fid: string;
  title: string;
  description: React.ReactNode;
  image: string;
  onViewDetails: () => void;
}

function CustomCard({ fid, title, description, image, onViewDetails }: CustomCardProps) {
  const [imgSrc, setImgSrc] = useState(image);
  const handleImageError = () => {
    setImgSrc(`${process.env.PUBLIC_URL}/images/no-photo-available.png`);
  };
  const navigate = useNavigate();
  const handleMonitor = (id: string) => {

    navigate(`/floodgate/monitor/${id}`);
  };
  return (
    <StyledPaper>
      <Box
        component="img"
        src={imgSrc}
        alt={title}
        onError={handleImageError}
        sx={{
          height: 140,
          width: '100%',
          objectFit: 'cover',
          borderRadius: '8px',
          marginBottom: 2,
        }}
      />
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h6" color="text.secondary" gutterBottom>
        {description}
      </Typography>
      <Button variant="contained" style={{ marginRight: '1rem' }} color="primary" size="small" onClick={onViewDetails}>
        ดูข้อมูลเพิ่มเติม
      </Button>
      <Button variant="contained" color="primary" size="small" onClick={() => handleMonitor(fid)}>
        monitor
      </Button>
    </StyledPaper>
  );
}

export default function FloodGateListMonitor() {
  const dispatch = useAppDispatch();
  const { dataByUser, isLoadingByUser, isErrorByUser, errorMessageByUser } = useAppSelector(floodGateInfoSelector);

  const [open, setOpen] = useState(false);
  const [currentSettings, setCurrentSettings] = useState<SettingNode[]>([]);
  const [currentFloodgateName, setCurrentFloodgateName] = useState<string>('');
  const [currentFloodgateID, setCurrentFloodgateID] = useState<string>('');
  const [socketData, setSocketData] = useState<{ [key: string]: { [key: string]: string } }>({});

  useEffect(() => {
    dispatch(fetchFloodGateInfo(floodgateAction.getAllByUser));

    const socket = io(config.ws.ws_floodgate_guest_url);

    socket.on('data', (data) => {
      const newData = data.reduce((acc: any, item: any) => {
        acc[item.floodgate_id] = item.floodgate_transaction_details;
        acc[item.floodgate_id].floodgate_transaction_createdate = item.floodgate_transaction_createdate;
        return acc;
      }, {});

      setSocketData(newData);
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  const handleOpen = (settings: SettingNode[], floodgateName: string, floodgateID: string) => {
    setCurrentSettings(settings);
    setCurrentFloodgateName(floodgateName);
    setCurrentFloodgateID(floodgateID);
    setOpen(true);
  };

  const handleArr = (setting: SettingNode, data: string) => {
    if (data != "0") {
      if (setting.setting_node_data_type == "1") { // 1 = Array
        const dataArray = data.split(',');
        const index = parseInt(setting.setting_node_sequence);
        if (index >= 0 && index < dataArray.length) {
          const inputValue = parseFloat(dataArray[index]);
          if (!isNaN(inputValue)) {
            return inputValue.toFixed(2);
          }
          return dataArray[index];
        } else {
          return "0";
        }
      } else {
        return data;
      }
    }
    return "0";
  }

  const handleClose = () => {
    setOpen(false);
  };

  if (isLoadingByUser) return <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />;
  if (isErrorByUser) return <Typography>เกิดข้อผิดพลาด: {errorMessageByUser}</Typography>;

  const transformedData = dataByUser
  ? dataByUser.map(({ floodgate_info }: any) => ({
      ...floodgate_info,
      setting_nodes: floodgate_info.setting_nodes.map((node: any) => ({
        ...node,
        setting_type: { ...node.setting_type },
      })),
    }))
  : [];

  return (
    <div>
      <TransparentAccordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">โครงการที่รับผิดชอบ</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {transformedData.map((info: FloodgateInfo) => {
              const latestSettings = info.setting_nodes.filter(item => item.setting_node_data_display_main).slice(0,2).map((setting: SettingNode, index: number) => (
                <React.Fragment key={`${setting.setting_node_data_key}-${index}`}>
                  <Box>
                    {setting.setting_node_data_name} : {handleArr(setting, socketData[info.floodgate_id]?.[setting.setting_node_data_key] || '0') || '0'} {setting.setting_node_data_unit}
                  </Box>
                </React.Fragment>
              ));

              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={info.floodgate_id}>
                  <CustomCard
                    fid={info.floodgate_id}
                    title={info.floodgate_name}
                    description={latestSettings}
                    image={info.floodgate_attachment_file}
                    onViewDetails={() => handleOpen(info.setting_nodes, info.floodgate_name, info.floodgate_id)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </TransparentAccordion>
      <CustomModal
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>

        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '90%', sm: 'auto', md: 'auto' },  // ขนาด responsive สำหรับ md ให้กว้างตามเนื้อหา
            maxWidth: '100%',  // จำกัดขนาดกว้างสุดไม่ให้เกิน 100% ของหน้าจอ
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            maxHeight: '500px', // กำหนดความสูงสูงสุด
            overflowY: 'auto',  // ให้สามารถเลื่อนดูข้อมูลได้ถ้าสูงเกิน
          }}
        >
          <Typography variant="h5" textAlign="center" gutterBottom>
            ข้อมูลเพิ่มเติม
            <Box component="span" sx={{ display: { xs: 'block', sm: 'inline' } }}>
              ({currentFloodgateName})
            </Box>
          </Typography>
          {currentSettings.map((setting, index) => (
            setting.setting_node_data_display_main ?
            <SettingItem key={index} sx={{
              mb: 2,
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },  // เรียงลงมาในมือถือ, เรียงแถวในเดสก์ท็อป
              justifyContent: 'center',  // จัดให้อยู่ตรงกลางทั้งแนวตั้งและแนวนอน
              alignItems: 'center',      // จัดให้อยู่ตรงกลางในแนวตั้ง
              gap: { xs: 1, md: 2 },     // ระยะห่าง
              textAlign: 'center',       // จัดข้อความให้อยู่ตรงกลางทั้งหมด
              whiteSpace: { xs: 'normal',sm:'nowrap', md: 'nowrap' },  // ห้ามขึ้นบรรทัดใหม่เมื่อหน้าจอเป็น md ขึ้นไป
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography
                  variant="h5"
                  textAlign="center"
                  gutterBottom
                >
                  <Box component="span" ml="1rem" sx={{ display: { xs: 'block', sm: 'inline' } }}>
                    {setting.setting_node_data_name} :
                  </Box>
                  <Box component="span" ml="1rem" sx={{ display: { xs: 'block', sm: 'inline' } }}>
                    {handleArr(setting, socketData[currentFloodgateID]?.[setting.setting_node_data_key] || '0') || '0'} {setting.setting_node_data_unit}
                  </Box>
                  <Box component="span" ml="1rem"
                    sx={{
                      marginLeft: 2,
                      display: { xs: 'block', sm: 'inline' },
                      backgroundColor: 'rgba(95, 163, 224, 0.8)',
                      padding: '2px 4px',
                      borderRadius: '4px',
                      whiteSpace: 'nowrap',  // ห้ามขึ้นบรรทัดใหม่ในส่วนนี้
                    }}>
                    {socketData[currentFloodgateID]?.['floodgate_transaction_createdate']}
                  </Box>
                </Typography>
              </Box>
            </SettingItem>
            :""
          ))}
        </Box>

        </Fade>
      </CustomModal>
    </div>
  );
}