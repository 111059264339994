import { Box, Button, Card, CardActions, CardContent, Grid, Modal, Paper, styled, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LineChartComponent from '../../layouts/Charts/LineChartComponent';
import { SettingNode } from '../../../models/monitor/MonitorDetailResponse.model';
import { useDispatch } from 'react-redux';
import { controlFloodgate, controlFloodgateSelector } from '../../../stores/slices/ControlFloodgateSlice';
import { useAppDispatch, useAppSelector } from '../../../stores/store';
import { userRolesSelector } from '../../../stores/slices/UserRolesSlice';
import { eUserRole } from '../../../services/Constants/enum/enum.model';
import Swal from 'sweetalert2';
import { fetchTransationMonitor, transationMonitorSelector } from '../../../stores/slices/FloodgateTransationMonitorSlice';

interface CardImageProps {
    path?: string;
    imagename?: string;
    settingNodes?: SettingNode;
    socketData: { [key: string]: { [key: string]: string } };
}

const handleArr = (setting: SettingNode, data: string) => {
    if (data != "0") {
        if (setting.setting_node_data_type == "1") { // 1 = Array
            const dataArray = data.split(',');
            const index = parseInt(setting.setting_node_sequence);
            if (index >= 0 && index < dataArray.length) {
                const inputValue = parseFloat(dataArray[index]);
                if (!isNaN(inputValue)) {
                    return inputValue.toFixed(2);
                }
                return dataArray[index];
            } else {
                return "0";
            }
        } else {
            return data;
        }
    }
    return "0";
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderRadius: '12px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
    marginBottom: 10
}));

const CircleComponent = ({ color = 'black', headColor = 'yellow' }) => {
    return (
        <Box
            sx={{
                width: 20,
                height: 20,
                backgroundColor: `${headColor}`,
                border: `1px solid ${color}`,
                borderRadius: '50%',
            }}
        />
    );
};


const CardImage: React.FC<CardImageProps> = ({ path = "FlapGateWeirCurved1", imagename = "0", settingNodes, socketData }) => {
    const dispatch = useAppDispatch();

    const { data } = useAppSelector(controlFloodgateSelector);


    const { data: initial_data } = useAppSelector(transationMonitorSelector);
    const { data: userRole } = useAppSelector(userRolesSelector);

    const [datachart, setDatachart] = useState<Array<{ name: string, [key: string]: string }>>([]);

    const floodgateId = settingNodes?.floodgate_id;
    const nodeData = floodgateId && socketData[floodgateId]?.[settingNodes?.setting_node_data_key || ""] || "0";

    const imagepath = `/ProjectImages/${settingNodes?.nodetype_input_and_display.map(item => item.img_group_name)}/${handleArr(settingNodes!, nodeData)}.png`
    const [imgSrc, setImgSrc] = useState(imagepath);
    const handleImageError = () => {
        setImgSrc(`${process.env.PUBLIC_URL}/images/no-photo-available.png`);
    };

    const light_color = "#888888";
    // สร้าง state สำหรับ modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [control, setControl] = useState<string>("");
    const handleChange = (event: any) => {
        setControl(event.target.value)
    }

    const isTextSubmit = async (param: string, btn_name?: string) => {
        if (floodgateId && settingNodes?.setting_node_id && param) {
            const result = await Swal.fire({
                title: 'คุณแน่ใจหรือไม่?',
                text: `คุณจะดำเนินการ ${btn_name} ใช่หรือไม่`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'ใช่, ดำเนินการ!',
                cancelButtonText: 'ไม่, ยกเลิก'
            });

            if (result.isConfirmed) {
                try {
                    dispatch(controlFloodgate({
                        floodgate_id: floodgateId,
                        setting_node_id: settingNodes?.setting_node_id,
                        value: param
                    }));
                    if (data?.data.response && data.data.response.floodgate_data) {
                        const responseString = Object.entries(data.data.response.floodgate_data)
                            .map(([key, value]) => `${key}: ${value}`)
                            .join(', ');
                        Swal.fire('ดำเนินการเรียบร้อย.', `${responseString}`, 'success');
                    } else {
                        Swal.fire('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลที่ต้องการแสดงผล', 'error');
                    }
                    // Swal.fire('ดำเนินการเรียบร้อย.', `${data?.data.response.floodgate_data}`, 'success');
                } catch (error: any) {
                    Swal.fire('ดำเนินการไม่สำเร็จ', error.message, 'error');
                }
            }
        } else {
            console.error("Missing data to control floodgate");
        }
    }

    const createDataChart = (setting: SettingNode, data: any, prevDataChart: Array<{ name: string, [key: string]: string }>) => {
        const sensorData = data[setting.floodgate_id]?.[setting.setting_node_data_key];
        const transactionDate = data[setting.floodgate_id]?.floodgate_transaction_createdate;

        if (!sensorData || !transactionDate) {
            return prevDataChart;
        }

        // Format time from transactionDate (HH:mm)
        const transactionTime = new Date(transactionDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        // Handle the array data and format the value for chart
        const sensorValue = handleArr(setting, sensorData);

        // Add the new data into datachart
        const updatedData = [
            ...prevDataChart,
            {
                name: transactionTime,
                [setting.setting_node_data_name]: sensorValue
            }
        ];
        if (updatedData.length > 10) {
            updatedData.shift(); // Remove the oldest entry
        }

        return updatedData;
    };

    useEffect(() => {
        dispatch(fetchTransationMonitor({ floodgate_id: floodgateId! }));
    }, [dispatch]);

    useEffect(() => {
        if (datachart.length == 0) {
            initial_data?.forEach((data) => {
                setDatachart((prevDataChart) => createDataChart(settingNodes!, data, prevDataChart));
            });
        } else {
            setDatachart((prevDataChart) => createDataChart(settingNodes!, socketData, prevDataChart));
        }
    }, [socketData, settingNodes]);

    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <Grid container spacing={2} sx={{ padding: 2 }}> {/* ปรับการจัดการ Grid ให้ถูกต้อง */}
            <Grid item xs={12} sm={12} md={12} sx={{
                backgroundColor: 'rgb(0 124 255 / 9%)',
                padding: 2,
                borderRadius: "0.6rem"
            }}>
                {settingNodes?.nodetype_input_and_display
                    .map((display, index) => {
                        // แสดง Card สำหรับ is_control
                        if (display.is_control) {
                            return (
                                <Card
                                    className="shadow-lg rounded-lg"
                                    key={`control-${index}`}
                                    sx={{
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                                        transition: 'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                        marginBottom: 2,
                                    }}
                                >
                                    <div className="bg-gray-100 p-4 border-b border-gray-200">
                                        <Typography variant="h6" component="div">
                                            การควบคุม : {settingNodes?.setting_node_data_name}
                                        </Typography>
                                    </div>

                                    <CardContent>
                                        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                            {settingNodes?.nodetype_input_and_display.map((display, index) => (
                                                display.is_btn ? (
                                                    <Button
                                                        key={index}
                                                        onClick={() => isTextSubmit(display.btn_value, display.btn_name)}
                                                        variant="contained"
                                                        style={{ backgroundColor: display.btn_color, marginRight: 10 }}
                                                        className="bg-blue-500"
                                                    >
                                                        {display.btn_name}
                                                    </Button>
                                                ) : display.is_text_input && index === 0 ? (
                                                    <Box
                                                        key={`input-${index}`}
                                                        display="flex"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        gap={2}
                                                        sx={{ width: '100%' }}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            label={display.text_input_name}
                                                            value={control}
                                                            onChange={handleChange}
                                                            sx={{ flexGrow: 1 }}
                                                        />
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className="bg-blue-500"
                                                            sx={{ width: 'auto', height: '55px', whiteSpace: 'nowrap' }}
                                                            onClick={() => isTextSubmit(control, display.text_input_name)}
                                                        >
                                                            ดำเนินการ
                                                        </Button>
                                                    </Box>
                                                ) : null
                                            ))}
                                        </Box>
                                    </CardContent>

                                    <CardActions className="p-4 content-center" />
                                </Card>
                            );
                        }

                        // แสดง Card สำหรับ is_status_light
                        if (display.is_status_light) {
                            return (
                                <Card
                                    className="shadow-lg rounded-lg"
                                    key={`status-light-${index}`}
                                    sx={{
                                        textAlign: 'center',
                                        borderRadius: '12px',
                                        backgroundColor: 'rgba(255, 255, 255, 1)',
                                        boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                                        transition: 'transform 0.2s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.05)',
                                        },
                                        marginBottom: 2,
                                    }}
                                >
                                    <div className="bg-gray-100 p-4 border-b border-gray-200">
                                        <Typography variant="h6" component="div">
                                            สถานะระบบ
                                        </Typography>
                                    </div>

                                    <CardContent>
                                        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="flex-start">
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="flex-start"
                                                marginRight={2}
                                                marginBottom={2}
                                            >
                                                <CircleComponent
                                                    color="black"
                                                    headColor={display.status_light_name === 'เปิด' ? display.status_light_color : light_color}
                                                />
                                                <Typography variant="body1" marginLeft={1}>
                                                    {display.status_light_name}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </CardContent>

                                    <CardActions className="p-4 content-center" />
                                </Card>
                            );
                        }

                        if (display.is_monitor) {
                            return (
                                <>
                                    {index === 0 && (
                                        <StyledPaper>
                                            <Typography variant="h6" textAlign="left" gutterBottom>
                                                {settingNodes?.setting_node_data_name}
                                            </Typography>
                                            <Typography variant="h3" textAlign="center" gutterBottom>
                                                {handleArr(settingNodes!, nodeData)}
                                            </Typography>
                                            <Typography variant="h5" textAlign="center" gutterBottom>
                                                {settingNodes?.setting_node_data_unit}
                                            </Typography>
                                        </StyledPaper>
                                    )}
                                    {display.is_img && (
                                        <StyledPaper>
                                            <Typography variant="h6" textAlign="left" gutterBottom>
                                                รูปภาพกราฟฟิก : {settingNodes?.setting_node_data_name}
                                            </Typography>
                                            <Box
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box
                                                    component="img"
                                                    src={imgSrc}
                                                    alt="Image"
                                                    onError={handleImageError}
                                                    sx={{ width: '50%', height: "auto", borderRadius: '8px', boxShadow: 3 }}
                                                />
                                            </Box>
                                        </StyledPaper>
                                    )}

                                    {display.is_graph && (
                                        <>
                                            <StyledPaper onClick={handleOpen}>
                                                <Typography variant="h6" textAlign="left" gutterBottom>
                                                    กราฟแสดงค่า : {settingNodes?.setting_node_data_name}
                                                </Typography>
                                                <LineChartComponent datachart={datachart} setting_node_data_name={settingNodes.setting_node_data_name} height={200} graph_color={display.graph_color} />
                                            </StyledPaper>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                aria-labelledby="modal-title"
                                                aria-describedby="modal-description"
                                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: {
                                                            xs: '90%', // หน้าจอเล็กสุด (มือถือ) ให้ใช้เต็มความกว้าง
                                                            sm: '90%', // หน้าจอ sm (เล็ก) ให้ใช้ 90%
                                                            md: '80%', // หน้าจอขนาดกลางให้ใช้ 80%
                                                            lg: '70%', // หน้าจอขนาดใหญ่ให้ใช้ 70%
                                                        },
                                                        height: {
                                                            xs: '60%', // หน้าจอ xs (มือถือ) ให้สูง 80%
                                                            sm: '70%', // หน้าจอ sm ให้สูง 70%
                                                            md: '90%', // หน้าจอขนาดกลางให้สูง 90%
                                                            lg: '90%', // หน้าจอขนาดใหญ่ให้สูง 90%
                                                        },
                                                        backgroundColor: 'white',
                                                        borderRadius: '10px',
                                                        boxShadow: 24,
                                                        padding: 4,
                                                        position: 'relative',
                                                        display: 'flex', // ใช้ flexbox เพื่อจัด layout ภายใน
                                                        flexDirection: 'column', // จัดเรียง items แนวตั้ง
                                                        overflow: 'hidden', // ซ่อนเนื้อหาที่ล้น
                                                    }}
                                                >
                                                    <Typography id="modal-title" variant={isMobile ? 'h6' : 'h4'} gutterBottom>
                                                        กราฟแสดงค่า : {settingNodes?.setting_node_data_name}
                                                    </Typography>

                                                    <Box
                                                        sx={{
                                                            flexGrow: 1, // ให้ LineChartComponent เติบโตเต็มที่ที่เหลืออยู่
                                                            overflow: 'auto', // ทำให้ Chart เลื่อนถ้าเกินขนาด
                                                        }}
                                                    >
                                                        <LineChartComponent
                                                            datachart={datachart}
                                                            setting_node_data_name={settingNodes.setting_node_data_name}
                                                            height={'100%'} // ถ้าเป็น mobile ให้สูง 50%, ถ้าไม่ใช่ให้สูง 100%
                                                            graph_color={display.graph_color}
                                                        />
                                                    </Box>

                                                    {/* ปุ่มปิด อยู่ที่มุมขวาบน */}
                                                    <Button
                                                        onClick={handleClose}
                                                        variant="contained"
                                                        sx={{
                                                            position: 'absolute',
                                                            top: 16,
                                                            right: 16,
                                                        }}
                                                    >
                                                        ปิด
                                                    </Button>
                                                </Box>
                                            </Modal>
                                        </>
                                    )}

                                </>
                            );
                        }
                        return null; // กรณีอื่นๆ จะไม่แสดงอะไรเลย
                    })}
            </Grid>
        </Grid>
    );
}

export default CardImage;
