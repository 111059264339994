import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import config from "../../config";
import { AddFloodgateRequest, UpdateFloodgateRequest, DeleteFloodgateRequest } from "../../models/manageFloodgateInfo/ManageFloodgateInfoReq.model";
import { ManageFloodgateInfoResponse } from "../../models/manageFloodgateInfo/ManageFloodgateInfoRes.model";
import { TOKEN } from "../../services/Constants/systems";
import { httpClient } from "../../services/Connection/httpclient";
import { floodgateManageAction } from '../../services/Constants/enum/enum.model';

// Interface สำหรับ State
interface ManageFloodgateInfoState {
    data: ManageFloodgateInfoResponse['data']['response'] | null;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string | null;
}

// Initial state
const initialState: ManageFloodgateInfoState = {
    data: null,
    isLoading: false,
    isError: false,
    errorMessage: null,
};

// Async thunk สำหรับการจัดการ floodgate
export const manageFloodgateInfo = createAsyncThunk<
    ManageFloodgateInfoResponse['data']['response'],
    AddFloodgateRequest | UpdateFloodgateRequest | DeleteFloodgateRequest,
    { rejectValue: string }
>(
    "manageFloodgateInfo/manageFloodgateInfo",
    async (floodgateInfo, { rejectWithValue }) => {
        try {
            const token = localStorage.getItem(TOKEN);
            if (!token) {
                return rejectWithValue("Token is missing");
            }

            let actionHeader = '';
            switch (floodgateInfo.action) {
                case floodgateManageAction.ADD:
                    actionHeader = 'ADD';
                    break;
                case floodgateManageAction.UPDATE:
                    actionHeader = 'UPDATE';
                    break;
                case floodgateManageAction.DELETE:
                    actionHeader = 'DELETE';
                    break;
                default:
                    return rejectWithValue("Invalid action");
            }

            // ลบ action ออกจาก request body
            const { action, ...body } = floodgateInfo;

            const response = await httpClient.post(
                config.floodgate.floodgate_url + config.floodgate.floodgate_manage,
                body,
                {
                    headers: {
                        'authorization': token,
                        'action': actionHeader,
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status) {
                return response.data.data.response;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error: any) {
            return rejectWithValue(error.message || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง");
        }
    }
);

// สร้าง Slice
const manageFloodgateInfoSlice = createSlice({
    name: "manageFloodgateInfo",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(manageFloodgateInfo.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.errorMessage = null;
            })
            .addCase(
                manageFloodgateInfo.fulfilled,
                (state, action: PayloadAction<ManageFloodgateInfoResponse['data']['response']>) => {
                    state.isLoading = false;
                    state.data = action.payload;
                }
            )
            .addCase(
                manageFloodgateInfo.rejected,
                (state, action: PayloadAction<string | undefined>) => {
                    state.isLoading = false;
                    state.isError = true;
                    state.errorMessage = action.payload || "เกิดข้อผิดพลาด หรือ session หมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้ง";
                }
            );
    },
});

export const manageFloodgateInfoSelector = (store: RootState) => store.manageFloodgateInfo;
export default manageFloodgateInfoSlice.reducer;